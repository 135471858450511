import React from 'react'
import marked from 'marked'
import { Helmet } from 'react-helmet'
import { PageProps, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { HomePage as HomePageType } from '../typings'
import { Layout } from '../components/Layout'
import { Hero } from '../components/Hero'
import { Buttons } from '../components/Button'
import { Card } from '../components/Card'
import { Carousel, Slide } from '../components/Carousel'
import { ProductsGrid } from '../components/Products'
import { Banner } from '../components/Banner'

interface HomePageProps {
  strapiHomepage: HomePageType
}

const HomePage = ({ data }: PageProps<HomePageProps>) => {
  const { hero, infoSlides, productSlides, productList, banner } = data.strapiHomepage

  return (
    <Layout>
      <Helmet>
        <html lang="de" />
        <meta charSet="utf-8" />
        <meta name="description" content={hero.description} />
        <title>meinAPOVERLAG</title>
      </Helmet>
      <Hero>
        <div className="max-w-2xl -mt-32 flex flex-col items-center">
          <h1 className="text-2xl lg:text-3xl text-center font-bold mb-4">{hero.title}</h1>
          <p className="text-lg lg:text-xl text-center">{hero.description}</p>
          {hero.buttons?.length && (
            <div className="flex flex-col md:flex-row md:justify-center md:flex-wrap gap-2 md:gap-4 mt-8">
              <Buttons buttons={hero.buttons} className="sm:w-60" />
            </div>
          )}
        </div>
      </Hero>
      <div className="mb-8 sm:mb-12 md:mb-16 lg:mb-20 space-y-8 sm:space-y-12 md:space-y-16 lg:space-y-20">
        {infoSlides?.length && (
          <Card className="lg:h-112 w-full md:w-screen-sm lg:w-screen-md mx-auto -mt-32 relative z-50">
            <Carousel dots>
              {infoSlides.map((slide) => {
                const image = getImage(slide.image?.localFile)
                return (
                  <Slide
                    key={slide.id}
                    title={slide.name}
                    className="flex flex-col md:flex-row items-center md:justify-center gap-4 md:gap-8"
                  >
                    {image && <GatsbyImage className="rounded-md shadow-md md:flex-1" image={image} alt={slide.name} />}
                    <div className="md:flex-1">
                      <div
                        className="markdown md:text-lg text-gray-600 leading-snug"
                        dangerouslySetInnerHTML={{ __html: marked(slide.content) }}
                      />
                    </div>
                  </Slide>
                )
              })}
            </Carousel>
          </Card>
        )}
        {productSlides?.length && (
          <section>
            <h2 className="max-w-max text-2xl lg:text-3xl font-bold mb-4 md:mb-6 lg:mb-8 pb-1 border-b-2 border-primary">
              Kennen Sie schon unsere Produkte?
            </h2>
            <Card className="w-full">
              <Carousel dots>
                {productSlides.map((slide) => {
                  const image = getImage(slide.image?.localFile)
                  return (
                    <Slide
                      key={slide.id}
                      title={slide.name}
                      className="flex flex-col md:flex-row items-center justify-center gap-4 md:gap-8"
                    >
                      {image && (
                        <GatsbyImage
                          className="h-48 md:h-80 lg:h-96 w-48 md:w-80 lg:w-96 flex-shrink-0"
                          objectFit="contain"
                          image={image}
                          alt={slide.name}
                        />
                      )}
                      <div className="markdown custom-list-xl md:text-lg" dangerouslySetInnerHTML={{ __html: marked(slide.content) }} />
                    </Slide>
                  )
                })}
              </Carousel>
            </Card>
          </section>
        )}
        {productList?.length && (
          <section>
            <h2 className="max-w-max text-2xl lg:text-3xl font-bold mb-4 md:mb-6 lg:mb-8 pb-1 border-b-2 border-primary">
              Unsere Produkte im Überblick:
            </h2>
            <ProductsGrid items={productList} />
          </section>
        )}
      </div>
      {banner && <Banner banner={banner} />}
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    strapiHomepage {
      id
      hero {
        title
        description
        buttons {
          id
          text
          url
          type
        }
      }
      infoSlides {
        id
        name
        content
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 350, aspectRatio: 1.5)
            }
          }
        }
      }
      productSlides {
        id
        name
        content
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
      productList {
        id
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 48)
            }
          }
        }
        name
        description
        button {
          id
          text
          url
          type
        }
      }
      banner {
        title
        description
        buttons {
          id
          text
          url
          type
        }
      }
    }
  }
`
