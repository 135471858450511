import React, { useState, useCallback, useEffect, ReactElement } from 'react'
import { useEmblaCarousel } from 'embla-carousel/react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

type Slide = ReactElement<SlideProps>

interface CarouselProps {
  dots?: boolean
  children: Slide[] | Slide
}

export const Carousel = ({ dots = false, children }: CarouselProps) => {
  const count = React.Children.count(children)
  const [emblaRef, embla] = useEmblaCarousel({ loop: true, draggable: count > 1 })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const slides = count === 1 ? [children as Slide] : (children as Slide[])
  const titles = slides.map((slide) => slide.props.title).filter(Boolean) as string[]

  const scrollPrev = useCallback(() => {
    embla?.scrollPrev()
  }, [embla])

  const scrollNext = useCallback(() => {
    embla?.scrollNext()
  }, [embla])

  const scrollTo = useCallback(
    (index) => {
      embla?.scrollTo(index)
    },
    [embla]
  )

  useEffect(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', () => {
      setSelectedIndex(embla.selectedScrollSnap())
    })
  }, [embla, setScrollSnaps])

  return (
    <div className="h-full w-full flex flex-col relative">
      {titles.length > 0 && (
        <div className="w-full flex justify-center p-4 gap-4 md:gap-8">
          {titles.map((title, index) => (
            <button
              key={index}
              className={`sm:text-lg font-bold break-words overflow-x-hidden border-b-2 focus:outline-none ${
                index === selectedIndex ? 'border-primary' : 'border-transparent hover:border-primary-100'
              }`}
              onClick={() => scrollTo(index)}
            >
              {title}
            </button>
          ))}
        </div>
      )}
      <div className="h-full overflow-hidden" ref={emblaRef}>
        <div className="h-full flex">{slides}</div>
      </div>
      {count > 1 && (
        <>
          <button
            className="absolute left-0 top-1/2 -mt-3 text-5xl text-primary focus:outline-none"
            onClick={scrollPrev}
            aria-label="Vorherige Folie"
          >
            <HiChevronLeft />
          </button>
          <button
            className="absolute right-0 top-1/2 -mt-3 text-5xl text-primary focus:outline-none"
            onClick={scrollNext}
            aria-label="Nächste Folie"
          >
            <HiChevronRight />
          </button>
          {dots && (
            <div className="w-full p-4 flex justify-center gap-2">
              {scrollSnaps.map((_, index) => (
                <button
                  key={index}
                  className={`h-4 w-4 rounded-full border border-primary focus:outline-none ${index === selectedIndex && 'bg-primary'}`}
                  onClick={() => scrollTo(index)}
                  aria-label={titles[index]}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

interface SlideProps {
  title?: string
  className?: string
}

export const Slide = ({ className, children }: React.PropsWithChildren<SlideProps>) => (
  <div className={[`px-12 py-4 flex-full relative select-none`, className].filter(Boolean).join(' ')}>{children}</div>
)
