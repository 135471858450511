import React from 'react'
import marked from 'marked'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Info } from '../typings'
import { Card } from './Card'
import { SingleButton } from './Button'

interface ProductsGridProps {
  items: Info[]
}

export const ProductsGrid = ({ items }: ProductsGridProps) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 auto-rows-fr gap-4 md:gap-6">
    {items.map((product) => (
      <ProductCard key={product.id} product={product} />
    ))}
  </div>
)

interface ProductCardProps {
  product: Info
}

const ProductCard = ({ product }: ProductCardProps) => {
  const logo = getImage(product.logo.localFile)

  return (
    <Card className="p-4 md:p-6 flex flex-col items-center cursor-default">
      {logo ? <GatsbyImage className="mb-4" image={logo} alt={product.name} /> : <div className="h-12 mb-4" />}
      <p className="text-lg font-bold mb-2">{product.name}</p>
      <div className="w-full markdown custom-list" dangerouslySetInnerHTML={{ __html: marked(product.description) }} />
      <div className="flex-grow" />
      <SingleButton className="mt-4" button={product.button} />
    </Card>
  )
}
